<template>
  <loading v-if="isLoading" />
  <no-data-found v-else-if="isNoData" />
  <div class="contries-list" v-else>
    <div
      class="search d-flex row-flex justfiy-content-start mb-0 align-items-center"
    >
      <p class="my-1">
        {{ $t("g.stateList/page_head/search") }}
      </p>
      <b-form-input
        class="input col-lg-2 col-md-4 col-sm-8 mx-1"
        :placeholder="$t('g.stateList/page_head/placeholder/search')"
        v-model="searchTerm"
      />
    </div>
    <b-card class="m-0 p-1">
      <h3 class="head-tiltle">{{ $t("g.stateList/page_head") }}</h3>
      <vue-good-table
        class="w-100 m-2"
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table condensed custom-table"
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :row-style-class="rowStyleClassFn"
      >
        <template slot="table-column" slot-scope="props">
          <span
            class="text-nowrap d-flex justify-content-start px-1 column-text"
          >
            {{ $t(`g.${props.column.label}`) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.label === 'stateList/table/state_name'"
            class="text-nowrap d-flex justify-content-start p-1 column-text"
          >
            <b-link
              :to="{
                name: 'show-city',
                params: {
                  id: Id,
                  stateId: props.row.id,
                  stateName: props.row.name,
                },
              }"
            >
              {{ props.row.name }}
            </b-link>
          </span>
          <span
            v-else-if="props.column.label === 'stateList/table/state_available'"
            class="text-nowrap d-flex justify-content-start p-1"
          >
            <b-form-checkbox
              :disabled="
                globalPermission.cities && !globalPermission.cities.update
              "
              v-model="props.row.is_available"
              @change="updateState(props.row.id, props.row.is_available)"
              button-variant="success"
              switch
            />
          </span>
          <span v-else class="text-nowrap d-flex justify-content-start p-1">
            {{ props.row.code }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BLink,
  BFormCheckbox,
  BPagination,
  BAvatar,
  BFormSelect,
  BFormInput,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import loading from "@/components/shared/loading/loading.vue";
import NoDataFound from "@/components/shared/NoDataFound";
export default {
  name: "CountriesList",
  components: {
    BCard,
    BRow,
    VueGoodTable,
    loading,
    BLink,
    BFormCheckbox,
    BAvatar,
    BFormInput,
    BFormSelect,
    BPagination,
    NoDataFound,
  },
  mounted() {
    this.getData();
  },

  data() {
    return {
      isLoading: false,
      rows: [],

      columns: [
        {
          label: "stateList/table/state_name",
          field: "name",
        },
        {
          label: "stateList/table/state_available",
          field: "is_available",
          sortable: false,
        },
      ],
      searchTerm: null,
      pageLength: 5,
      isNoData: false,
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const data = await this.$http.get(`admin/countries/${this.Id}/states`);
        this.rows = data.data.data;
      } catch (err) {
        if (err.response.status == 400) {
          this.isNoData = true;
        } else {
          this.$helpers.handleError(err);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateState(id, current) {
      try {
        // handeling data
        let formData = new FormData();
        formData.append("is_available", current ? 1 : 0);
        const res = await this.$http.post(
          `admin/countries/${this.Id}/states/${id}?_method=put`,
          formData
        );
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    rowStyleClassFn(row) {
      const objectIndex = this.rows.findIndex((ele) => ele.id === row.id) + 1;
      return objectIndex % 2 == 0 ? "table-row-even" : "table-row-odd";
    },
  },
  computed: {
    Id() {
      if (this.$route.params.id) {
        sessionStorage.setItem("countryId", this.$route.params.id);

        return this.$route.params.id;
      } else {
        return sessionStorage.getItem("countryId");
      }
    },
    countryName() {
      return this.$route.query.countryName;
    },
  },
};
</script>

<style src="./_index.scss" lang="scss" />
