var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):(_vm.isNoData)?_c('no-data-found'):_c('div',{staticClass:"contries-list"},[_c('div',{staticClass:"search d-flex row-flex justfiy-content-start mb-0 align-items-center"},[_c('p',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.$t("g.stateList/page_head/search"))+" ")]),_c('b-form-input',{staticClass:"input col-lg-2 col-md-4 col-sm-8 mx-1",attrs:{"placeholder":_vm.$t('g.stateList/page_head/placeholder/search')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('b-card',{staticClass:"m-0 p-1"},[_c('h3',{staticClass:"head-tiltle"},[_vm._v(_vm._s(_vm.$t("g.stateList/page_head")))]),_c('vue-good-table',{staticClass:"w-100 m-2",attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed custom-table","sort-options":{
        enabled: false,
      },"search-options":{
        enabled: true,
        skipDiacritics: true,
        placeholder: _vm.$t('search_placeholder'),
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"row-style-class":_vm.rowStyleClassFn},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap d-flex justify-content-start px-1 column-text"},[_vm._v(" "+_vm._s(_vm.$t(("g." + (props.column.label))))+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.label === 'stateList/table/state_name')?_c('span',{staticClass:"text-nowrap d-flex justify-content-start p-1 column-text"},[_c('b-link',{attrs:{"to":{
              name: 'show-city',
              params: {
                id: _vm.Id,
                stateId: props.row.id,
                stateName: props.row.name,
              },
            }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1):(props.column.label === 'stateList/table/state_available')?_c('span',{staticClass:"text-nowrap d-flex justify-content-start p-1"},[_c('b-form-checkbox',{attrs:{"disabled":_vm.globalPermission.cities && !_vm.globalPermission.cities.update,"button-variant":"success","switch":""},on:{"change":function($event){return _vm.updateState(props.row.id, props.row.is_available)}},model:{value:(props.row.is_available),callback:function ($$v) {_vm.$set(props.row, "is_available", $$v)},expression:"props.row.is_available"}})],1):_c('span',{staticClass:"text-nowrap d-flex justify-content-start p-1"},[_vm._v(" "+_vm._s(props.row.code)+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.pagelength"))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.of"))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t("g.pageText"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }